@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-content {
  height: 100vh;
  flex: 1;
  overflow: scroll !important;
}

.custom-menu .ant-menu-item {
  white-space: normal;
  line-height: 1.5;
}

.custom-menu .ant-menu-item-icon {
  margin-right: 8px;
  vertical-align: top;
}

.with-no-label label::after {
  content: none;
}
